/* eslint-disable no-undef */
/* eslint-disable jquery/no-css */
/* eslint-disable jquery/no-class */
import get from 'lodash/get';

import emitter from 'hb-react/shared/utils/emitter';

import { setIsMobileApp } from '../mobile';

export const Hivebrite = {
  data: {
    jwt: null,
  },
  router: {
    callbacks: {
      routerOnChangeCallback: null,
    },
  },
  wsState: {
    ready: false,
  },
};

const cssClassNameNoNavigationHeader = 'no-navigation-header';

export const HivebriteMobileApp = {
  v1: {
    header: {
      hidePO: () => {
        $('header').addClass(cssClassNameNoNavigationHeader);

        setIsMobileApp(true);
      },
      hideFO: () => {
        if ($('body').hasClass('js-homepage-po')) {
          return;
        }

        $('body').addClass(cssClassNameNoNavigationHeader);
        emitter.emit('layoutReposition');
        emitter.emit('setHeaderHeightForMobileView');
        $('#main-container').css('padding-top', 0);

        setIsMobileApp(true);
      },
    },
    callToActionMenu: {
      show: () => {
        emitter.emit('openModal');
      },
      hide: () => {
        emitter.emit('closeModal');
      },
      createContent: (params) => {
        emitter.emit('createContent', params);
      },
    },
    nativeConsent: {
      reject: () => {
        emitter.emit('nativeRejectedUserConsent');
      },
    },
  },
  v2: {
    config: window?.HivebriteMobileApp?.v2?.config ?? {},
    getConfig: (param) => get(window?.HivebriteMobileApp?.v2?.config, param),
  },
};
