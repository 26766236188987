/* eslint-disable */
import requireAll from 'helpers/requireAll';
import { preventBouncingViewportIssueOniOS } from 'helpers/preventBouncingViewportIssueOniOS';
import 'locales/loadMoment';

// CSS entry point
require('legacy/stylesheets/homepage_network.scss');

// Libraries
require('jquery');
require('jquery-ujs');

// Gems
require('@nathanvda/cocoon');

require('select2');
require('responsive-slides/responsiveslides');
require('moment');
window.moment = require('moment-timezone');
require('libs/bootstrap/js/tooltip');
require('libs/bootstrap/js/dropdown');

require('libs/retina');

// jQuery Plugins
require('legacy/javascripts/plugins/beautifull_cover_size');
require('jquery-serializejson');
require('libs/jquery.fancybox');
require('legacy/javascripts/plugins/kit_fancybox_global');
require('libs/foundation-sites/js/foundation/foundation');
require('libs/foundation-sites/js/foundation/foundation.reveal');

// Components
require('react');
require('react-dom');
require('homepage_network_components');

// Self exposing on AlumniConnect object
window.AlumniConnect = {
  Helpers: {},
};

// exposing on Hivebrite object
const { Hivebrite } = require('hb-react/shared/utils/hivebrite');

window.Hivebrite = {
  ...(window.Hivebrite || {}),
  ...Hivebrite,
  // eslint-disable-next-line global-require
  WebsocketClient: require('websocket/client').default,
  sessionStorage: require('hb-react/shared/utils/sessionStorage'),
};

// sessionStorage keys
window.Hivebrite.sessionStorage.PO_LOGIN_SOURCE = 'PO_LOGIN_SOURCE';

// exposing on HivebriteMobileApp
window.HivebriteMobileApp =
  require('hb-react/shared/utils/hivebrite').HivebriteMobileApp;

// Helpers
requireAll(require.context('helpers', false));
require('helpers/global');

// Objects
require('legacy/javascripts/objects/experiences');
require('legacy/javascripts/objects/educations');

// Initializers
require('initializers/hammerhistory');
require('initializers/pickplace');
require('initializers/react');
import('initializers/hammerjs');

window.Loader = require('legacy/javascripts/objects/loader').default;

// Disable socket in whitelabelURL context
if (window.Hivebrite.whitelabelURL) {
  // eslint-disable-next-line global-require
  require('websocket/client').default.subscribe();
}

$(() => {
  // Sticky side container
  if (!AlumniConnect.Helpers.JS.isMobile.any()) {
    let sideContainer = document.querySelector('.js-content-side-container');

    if (sideContainer) {
      sideContainer.style.position = 'fixed';
      sideContainer.style.top = '120px';
    }
  }

  // Init plugins
  $('body').tooltip({
    selector: '[data-behavior=tooltip],[data-toggle=tooltip]',
  });
  $('[data-behavior=close-parent]').click(function () {
    $(this).parent().hide();
  });
  setTimeout(() => {
    $('[data-behavior=close-parent]').trigger('click');
  }, 15000);

  // Footer display
  $('.js-network__footer').show();

  // Disable socket in whitelabelURL context
  if (window.Hivebrite.whitelabelURL) {
    Hivebrite.WebsocketClient.subscribe();
  }

  // Preventing iOS Textbox Auto Zooming and ViewPort Sizing
  preventBouncingViewportIssueOniOS();
});
