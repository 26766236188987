/* global google */
/* eslint no-console: "off" */
/* eslint class-methods-use-this: "off" */
import { debounce } from 'lodash';

import * as errorReporting from 'hb-react/shared/utils/errorReporting';

import Search from './search';

class GoogleSearch extends Search {
  async source() {
    const { Geocoder } = await google.maps.importLibrary('geocoding');

    const geocoder = new Geocoder();

    const geocode = debounce((query, region, asyncResults) => {
      geocoder.geocode({ address: query, region }, (results, status) => {
        if (status === 'OK') {
          const transformedResults = results.map((result) => {
            const northEastLat = result.geometry.viewport.getNorthEast().lat();
            const northEastLng = result.geometry.viewport.getNorthEast().lng();
            const southWestLat = result.geometry.viewport.getSouthWest().lat();
            const southWestLng = result.geometry.viewport.getSouthWest().lng();

            const formattedBounds = [
              [northEastLat, southWestLng],
              [southWestLat, northEastLng],
            ];

            return {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              address_name: result.formatted_address,
              colloquial_area: this.info(result, 'colloquial_area'),
              neighborhood: this.info(result, 'neighborhood'),
              sublocality_level_1: this.info(result, 'sublocality_level_1'),
              city:
                this.info(result, 'locality') ||
                this.info(result, 'postal_town'),
              administrative_area_level_2: this.shortInfo(
                result,
                'administrative_area_level_2',
              ),
              administrative_area_level_1: this.shortInfo(
                result,
                'administrative_area_level_1',
              ),
              country: this.info(result, 'country'),
              country_code: this.shortInfo(result, 'country'),
              postal_code: this.info(result, 'postal_code'),
              location_level: result.types[0],
              bounds: formattedBounds,
            };
          });

          asyncResults(transformedResults);
        } else {
          if (status === 'ZERO_RESULTS' || status === 'OVER_QUERY_LIMIT') {
            asyncResults([]);

            return;
          }

          errorReporting.captureMessage(`Geocoder failed due to: ${status}`);
        }
      });
    }, 500);

    return (query, region, syncResults, asyncResults) => {
      syncResults([]);
      geocode(query, region, asyncResults);
    };
  }

  info(result, type) {
    const name = result.address_components.find(
      (component) => component.types.indexOf(type) !== -1,
    );

    return name && name.long_name;
  }

  shortInfo(result, type) {
    const name = result.address_components.find(
      (component) => component.types.indexOf(type) !== -1,
    );

    return name && name.short_name;
  }

  suggestionDisplay(data) {
    return data.address_name;
  }

  data(data) {
    return {
      colloquial_area: data.colloquial_area,
      neighborhood: data.neighborhood,
      sublocality_level_1: data.sublocality_level_1,
      city: data.city,
      administrative_area_level_2: data.administrative_area_level_2,
      administrative_area_level_1: data.administrative_area_level_1,
      country: data.country,
      country_code: data.country_code,
      postal_code: data.postal_code,
      location_level: data.location_level,
      lat: data.lat,
      lng: data.lng,
      bounds: data.bounds,
    };
  }

  static isReady() {
    return typeof google !== 'undefined' && google.maps;
  }
}

export default GoogleSearch;
