export const convertNetworkThemingToFrontofficeThemeVariables = ({
  theming = { fo: {} },
  fontBrightness,
}) => ({
  // Buttons variables
  buttonBorderRadius: theming.fo?.fo_button_border_radius,
  secondaryButtonFontBrightness:
    theming.fo?.fo_secondary_button_font_brightness,
  secondaryButtonBackgroundColor:
    theming.fo?.fo_secondary_button_background_color,

  // Inputs variables
  inputBackgroundColor: theming.fo?.fo_inputs_background_color,
  inputOutlineColor: theming.fo?.fo_inputs_outline_color,
  inputFontBrightness: theming.fo?.fo_inputs_font_brightness,

  // Frame variables
  frameBackgroundColor: theming.fo?.fo_frame_background_color,
  frameDropShadow: theming.fo?.fo_frame_drop_shadow,
  frameFontBrightness: theming.fo?.fo_frame_font_brightness,

  // Global border radius (Card, etc.)
  globalBorderRadius: theming.fo?.fo_frame_border_radius,

  // Font brightness
  fontBrightness,

  // AppHeader variables
  appHeaderBackgroundColor: theming.fo?.fo_page_header_background_color,
  appHeaderFontBrightness: theming.fo?.fo_page_header_font_brightness,
});
