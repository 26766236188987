import { initializeApp, logger } from '@hivebrite/frontend-sdk/src';
import type { DataDogConfig } from '@hivebrite/frontend-sdk/src';

const SERVICE = 'frontend-sdk';
const DATADOG_CONFIG: DataDogConfig = {
  clientToken: window.__HB_ENV__.DATADOG_FRONT_TOKEN,
  handler: {
    level: 'debug',
    name: 'datadog',
    type: 'http',
  },
  service: SERVICE,
  site: 'datadoghq.eu',
};

const thirdParty = window.__DATADOG_ACTIVATION__
  ? {
      datadog: {
        ...DATADOG_CONFIG,
      },
    }
  : undefined;

export const initializeFrontendSdk = () => {
  initializeApp({
    app: {
      // Force it to 'production' is you need to send DD logs from your local
      // You will also need to set flag-activate-front-monitoring-datadog to true on your local
      env: window.__HB_ENV__.ENVIRONMENT,
      cluster: window.__HB_ENV__.CLUSTER_NAME,
      office: window.__HB_OFFICE__,
      name: 'alumni_connect web SDK',
    },
    host: window.location.hostname,
    network:
      typeof window.__HB_NETWORK__ === 'undefined'
        ? undefined
        : {
            id: window.__HB_NETWORK__.id,
            identifier: window.__HB_NETWORK__.identifier,
          },
    source: SERVICE,
    service: SERVICE,
    remoteUrl: `${window.location.origin}/app/config`,
    version: window.__HB_ENV__.APP_VERSION,
    thirdParty,
  });

  // Waiting to have a unique entry point for the React App in the PO
  if (window.__HB_OFFICE__ === 'PO') {
    setTimeout(() => {
      // Init PO App logger
      logger.info('PO App is started correctly', ['UJB']);
    }, 3000);
  }
};
