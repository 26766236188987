import {
  QueryClient as ReactQueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const defaultQueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > 5 || error?.response?.status < 500) {
          return false;
        }

        return window.__HB_ENV__.ENVIRONMENT === 'production';
      },
      // Never refetch the data if we refocus the window
      refetchOnWindowFocus: false,
    },
  },
});

const QueryClientProvider = ({
  children,
  queryClient = defaultQueryClient,
}) => (
  <ReactQueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </ReactQueryClientProvider>
);

export default QueryClientProvider;
