/* eslint-disable class-methods-use-this */
import Search from './search';

// Bloodhound
require('corejs-typeahead');

class MapboxSearch extends Search {
  source() {
    const token = window.__HB_ENV__.MAPBOX_KEY;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/%QUERY.json?limit=5&access_token=${token}`;
    const engine = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url,
        wildcard: '%QUERY',
        transform: (response) => response.features,
      },
    });

    engine.initialize();

    return engine.ttAdapter();
  }

  suggestionDisplay(data) {
    return data.place_name;
  }

  data(data) {
    let city;
    let country;
    let countryCode;

    if (data.place_type.indexOf('place') > -1) city = data.text;
    if (data.context) {
      data.context.forEach((v) => {
        if (v.id.indexOf('place') >= 0) {
          city = v.text;
        }
        if (v.id.indexOf('country') >= 0) {
          country = v.text;
          countryCode = v.short_code;
        }
      });
    }

    const bounds = data.bbox || {};
    // Formatted bounds must looks like
    // [
    //   [northEastLat, northEastLng],
    //   [southWestLat, southWestLng],
    // ]
    const formattedBounds = [
      [bounds?.[3], bounds?.[2]],
      [bounds?.[1], bounds?.[0]],
    ];

    return {
      city,
      country,
      country_code: countryCode,
      location_level: data.place_type,
      lat: data.center[1],
      lng: data.center[0],
      bounds: formattedBounds,
    };
  }
}

export default MapboxSearch;
